<template>
  <div class="hello text-center max-w-2xl mx-auto p-16 align-middle my-auto">
    <h2 class="text-2xl font-bold min-h-screen pt-32">Website Under Maintenance</h2>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p{margin-top: 2%;}
</style>
